import React, { Suspense } from 'react'
import { Link } from 'gatsby'
import { Col, Row, Stack } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { TabsList } from '/src/components/common'
import { EnquiryList } from '/src/components/enquiry'
import { useUser } from '/src/hooks'

const EnquiriesPage = () => {
  const user = useUser()

  if (!user) {
    return <></>
  }

  return (
    <Suspense fallback={<></>}>
      <Layout hero={<HeroImage title="Enquiries" />}>
        <Row className="d-flex justify-content-center">
          <Col md={10} lg={8}>
            <Row className="mt-2 mb-4">
              <Col sm={6}>
                <div className="page-title">
                  <h1 className="title">My Enquiries</h1>
                </div>
              </Col>
              <Col sm={6} className="d-flex justify-content-end">
                <Stack direction="horizontal" gap={4}>
                  <Link className="button outline" to="/user/logout">
                    Logout
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </Stack>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3" sm={12}>
                <TabsList items={[
                    {
                      label: 'Account',
                      path: '/user'
                    },
                    {
                      label: 'Orders',
                      path: '/user/orders'
                    },
                    {
                      label: 'Enquiries',
                      path: '/user/enquiries'
                    },
                    {
                      label: 'Addresses',
                      path: '/user/addresses'
                    }
                  ]} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <EnquiryList pagination={true} limit={6} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </Suspense>
  )
}

export default EnquiriesPage

export const Head = () => <title>My Enquiries</title>
